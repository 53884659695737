// src/components/Footer.js
import React from 'react';
import { Typography, Box, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box mt={5} py={3} bgcolor="text.secondary" color="white" textAlign="center">
      <Typography variant="body2">
        © {new Date().getFullYear()} Alexander Fields. All rights reserved. If you want to see the code request access @ {' '}
        <Link href="https://www.github.com/roku674/website" target="_blank" rel="noopener noreferrer" color="inherit">
          GitHub
        </Link>
      </Typography>
    </Box>
    
  );
};

export default Footer;